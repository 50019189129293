<template>
  <div>
    <div class="news">
      <div class="title">
        GV20 Therapeutics Announces First Patient Dosed in Phase 1 Clinical Trial of GV20-0251 in Patients with Advanced Solid Tumors
      </div>
      <div class="time">
        Mar 24, 2023
      </div>
      <div class="content">
        
        <p>
          Cambridge, Mass. – March 24, 2023 – GV20 Therapeutics, a biotechnology company using cutting-edge genomics and artificial intelligence approaches to discover next-generation cancer therapeutics, announced that the first patient has been dosed with GV20-0251 in a Phase 1 clinical trial for patients with advanced solid tumors.
        </p>
        <p>
          “The initiation of this Phase 1 clinical trial is a significant milestone for GV20. GV20-0251 is our first drug candidate designed de novo by GV20’s proprietary AI algorithm to enter the clinic. It targets a novel immune checkpoint IGSF8 which was discovered using our STEAD (Simultaneous Target and Antibody Evaluation) platform integrating genomics, big data, and AI.” said Shirley Liu, Ph.D., CEO of GV20 Therapeutics. “We are excited to advance the development of a potential therapeutic with a novel mechanism of action to benefit cancer patients, including those not responding to current immunotherapies.”
        </p>
        <p>
          The Phase 1 trial (ClinicalTrials.gov Identifier: NCT05669430) is an open label, multicenter, dose escalation and expansion study of GV20-0251 in patients with advanced solid tumors who are not eligible for standard of care therapies. The study is designed to evaluate the safety, tolerability, pharmacokinetics, pharmacodynamics, and preliminary anti-tumor activity of GV20-0251 administered intravenously.          
        </p>
        <p>
          <div class="font-weight-bold">
            ABOUT GV20-0251
          </div>
          GV20-0251 is a first-in-class, fully human, Fc-attenuated IgG1 monoclonal antibody that binds to a novel immune checkpoint IGSF8 and blocks the interaction with its receptors. Preclinical data have demonstrated that antibody blockade of IGSF8 results in compelling monotherapy anti-tumor activity as well as synergy with anti-PD1 across multiple syngeneic tumor models.
        </p>
        <p>
          <div class="font-weight-bold">
            ABOUT GV20 THERAPEUTICS
          </div>
          GV20 Therapeutics is a biotechnology company with sites in Cambridge, Massachusetts and Shanghai, China. GV20 uses high-throughput functional genomics and artificial intelligence to identify novel cancer drug targets and antibody therapeutics. “GV20” references one of the most important acupoints for the human body, symbolizing the company’s mission to find and exploit vulnerabilities in cancer biology.
        </p>
        <p>
          To learn more about GV20, please visit <a style="color:#2d8cf0;" href="https://mobile.gv20tx.com/">https://gv20tx.com/</a> and follow the company on <a style="color:#2d8cf0;" href="https://www.linkedin.com/company/gv20tx/">LinkedIn</a>.
        </p>
        <p>
          <div class="font-weight-bold">
            Business Contact
          </div>
          Ying Gong, Ph.D., CBO<br>
          BD@gv20tx.com
        </p>

      </div>
    </div>
    <Footers />
  </div>
</template>
<script>
import Footers from '@/components/Footers'
export default {
  data () {
    return {

    }
  },
  components: {
    Footers
  }
}
</script>
<style scoped lang="scss">
.news {
  font-size: 30px;
  font-weight: 500;
  padding: 0 40px;
  .title {
    font-weight: bold;
    font-size: 40px;
    padding-top: 40px;
  }
  .time {
    font-size: 20px;
    color: #666666;
    font-style: italic;
    margin: 50px 0;
  }
  .item {
    .font-weight-bold {
      margin-bottom: 30px;
    }
  }
}
</style>

